import React, { useEffect } from 'react';
import SpaceScene from './SpaceScene';
import styled from 'styled-components';
import './App.css';
import gsap from 'gsap';
import messages from './messages.json';
import seedrandom from 'seedrandom';

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  flex-direction: column;
  margin-top: 15vh;
  overscroll-behavior: none;

  body {
    background-color: $blue;
  }

  .can-wrapper {
    display: flex;
  }
  .can {
    margin-right: 30px;
  }
  .can > img {
    width: 150px;
    height: auto;
  }
`;

const LettersPersonName = styled.h1`
  font-size: 3.5rem;
  color: #ffffff;
  white-space: nowrap;
  span {
    display: inline-block;
  }
`;

const LettersTexts = styled.h2`
  font-size: 1.8rem;
  white-space: nowrap;
  color: #ffffff;
`;

const LettersTextsRand = styled.h2`
  color: #ffffff; /* White text color */
  font-family: 'Roboto', sans-serif; /* Choose a suitable font */
  font-size: 1rem; /* Adjust font size as needed */
  letter-spacing: 2px; /* Increase letter spacing for clarity */
  text-align: center; /* Center-align text */
`;


function App() {


  useEffect(() => {
    // Your existing GSAP animations
  }, []);
  useEffect(() => {
    // GSAP Animation for LettersPersonName
    const letters = document.querySelectorAll('.letters');
    gsap.set(letters, { y: '-=20', opacity: 0, rotation: gsap.utils.random(-5, 5) });

    letters.forEach((letter, index) => {
      gsap.to(letter, {
        duration: gsap.utils.random(1, 2),
        y: '+=20',
        opacity: 1,
        rotation: gsap.utils.random(-2, 2),
        repeat: -1,
        yoyo: true,
        delay: gsap.utils.random(0, 0.5),
        ease: 'power1.inOut',
      });
    });

    // GSAP Animation for LettersTexts
    const text = document.querySelector('.texts');
    gsap.to(text, {
      duration: 1.5,
      opacity: 0.8,
      scale: 1.1,
      repeat: -1,
      yoyo: true,
      ease: 'power1.inOut',
    });
  }, []);
  const getRandomMessage = () => {
    // Get today's date
    const today = new Date();
    // Use the date as the seed for random number generation
    const seed = today.getDate();
    // Initialize the random number generator with the seed
    const rng = seedrandom(seed.toString());
    // Generate a random index within the range of messages
    const randomIndex = Math.floor(rng() * messages.mysterious_messages.length);
    // Retrieve and return the random message
    return messages.mysterious_messages[randomIndex].message;
  };
  


  return (
    <div>
      <SpaceScene />
      <Container className="canBox">
        <LettersPersonName>
          {Array.from('Ellen').map((letter, index) => (
            <span key={index} className="letters">
              {letter}
            </span>
          ))}
        </LettersPersonName>
        <LettersTexts className="texts">Universe whispers to you</LettersTexts>
        <LettersTextsRand>{getRandomMessage()}</LettersTextsRand></Container>
    </div>
  );
}

export default App;
