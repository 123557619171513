import React from 'react';
import styled, { keyframes } from 'styled-components';

const twinkle = keyframes`
  0% { opacity: 0.3; }
  50% { opacity: 1; }
  100% { opacity: 0.3; }
`;

const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

const SpaceBackground = styled.div`
  background-color: black;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  position: absolute;
  z-index: -99;
  top: 0;
`;

const Star = styled.div`
  position: absolute;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background-color: white;
  animation: ${twinkle} ${props => props.twinkleInterval}s infinite,
    ${rotate} ${props => props.rotateInterval}s linear infinite;
  opacity: 0.3;
`;

const SpaceScene = () => {
  const renderStars = () => {
    const stars = [];
    for (let i = 0; i < 200; i++) {
      const x = Math.random() * 100; 
      const y = Math.random() * 100; 
      const twinkleInterval = Math.random() * 2 + 3; 
      const rotateInterval = Math.random() * 10 + 5;
      stars.push(
        <Star
          key={i}
          style={{ left: `${x}vw`, top: `${y}vh` }}
          twinkleInterval={twinkleInterval}
          rotateInterval={rotateInterval}
        />
      );
    }
    return stars;
  };

  return <SpaceBackground>{renderStars()}</SpaceBackground>;
};

export default SpaceScene;